import React, { Fragment } from "react"
import { Link } from "gatsby"
import { Menu, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function Dropdown(props) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="inline-flex items-center px-1 pt-1 ml-4 text-sm font-medium border-b-2 border-transparent transition duration-150 ease-in-out hover:border-dark text-blue hover:text-yellow">
              {props.header}
              <ChevronDownIcon
                className={classNames(
                  open && "rotate-180",
                  "w-5 transform transition-transform duration-100 ease-in h-5 -mr-1"
                )}
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="absolute right-0 z-50 w-auto mt-2 bg-white shadow-lg origin-top-right rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div className="py-1">
                {props.items.map((item, i) => (
                  <Menu.Item key={i}>
                    {({ active }) => (
                      <Link
                        to={item.href}
                        className={classNames(
                          active ? "bg-gray-100 text-yellow" : "text-blue",
                          "block px-12 py-2 text-sm"
                        )}
                      >
                        {item.name}
                      </Link>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}
