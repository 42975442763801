import React from "react"
import { Link } from "gatsby"
import logo from "../images/logo.png"
import bbb from "../images/bbb.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookF,
  faTwitter,
  faGooglePlusG,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons"

const navigation = {
  support: [
    { name: "Home", href: "/" },
    { name: "Fujitsu", href: "/fujitsu" },
    { name: "Bryant", href: "/bryant" },
    { name: "Ruud", href: "/ruud" },
  ],
  company: [
    { name: "Maintenance", href: "/maintenance" },
    { name: "Fabrication", href: "/fabrication" },
    { name: "Service Area", href: "/service-area" },
    { name: "About Us", href: "/about-us" },
  ],
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fwww.alpineairandheat.net%2F",
      icon: faFacebookF,
    },
    {
      name: "Twitter",
      href: "https://twitter.com/intent/tweet?source=webclient&text=http%3A%2F%2Fwww.alpineairandheat.net%2F",
      icon: faTwitter,
    },
    {
      name: "Google Plus",
      href: "https://plus.google.com/share?url=http://www.alpineairandheat.net/",
      icon: faGooglePlusG,
    },
    {
      name: "Linked In",
      href: "https://www.linkedin.com/shareArticle?mini=true&url=http%3A%2F%2Fwww%2Ealpineairandheat%2Enet%2F",
      icon: faLinkedinIn,
    },
  ],
}

export default function Footer() {
  return (
    <footer className="bg-dark" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-4 xl:col-span-1">
            <img
              className="h-20"
              src={logo}
              alt="Alpine Air Conditioning and Heating"
            />
            <p className="text-base text-gray-400">
              Proudly serving Alpine, Amador, Calaveras, and El Dorado Counties
            </p>
            <div className="flex space-x-6">
              {navigation.social.map(item => (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  rel="noopenner noreferrer"
                  className="text-gray-400 hover:text-gray-200"
                >
                  <span className="sr-only">{item.name}</span>
                  <FontAwesomeIcon
                    className="text-xl"
                    icon={item.icon}
                    aria-hidden="true"
                  />
                </a>
              ))}
            </div>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <ul className="mt-4 text-gray-400 space-y-4">
                <li>
                  <p>Office Hours Monday-Friday</p>
                </li>
                <li>
                  <p>8:00am.-12:00pm. and 1:00pm.-4:00 pm.</p>
                </li>
                <li>
                  <p>Flexible Scheduling Available</p>
                </li>
                <li>
                  <p>(209) 295-6484</p>
                </li>
                <li>
                  <p>Pioneer, CA License #714582</p>
                </li>
              </ul>
              <div className="mt-12 md:mt-0">
                <ul className="mt-4 space-y-4">
                  {navigation.support.map(item => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-base text-gray-400 hover:text-gray-200"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid flex flex-col md:grid-cols-2 md:gap-8">
              <div className="order-last block mt-auto md:mt-0 md:order-first">
                <ul className="mt-4 space-y-4">
                  {navigation.company.map(item => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-base text-gray-400 hover:text-gray-200"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-4">
                <a
                  href="https://www.bbb.org/us/ca/pioneer/profile/heating-and-air-conditioning/alpine-air-conditioning-heating-1156-35003816#sealclick"
                  target="__blank"
                >
                  <img
                    src={bbb}
                    className="block w-16 mr-auto"
                    alt="bbb credentials"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-8 mt-12 border-t border-gray-200">
          <p className="text-base text-gray-400 xl:text-center">
            &copy; {new Date().getFullYear()} Alpine Air Conditioning and
            Heating
          </p>
        </div>
      </div>
    </footer>
  )
}
