import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Header = props => {
  return (
    <div className="relative overflow-hidden bg-blue">
      <div className="mx-auto max-w-7xl">
        <div className="relative z-10 pb-8 bg-blue sm:pb-16 lg:max-w-2xl lg:w-full">
          <svg
            className="absolute inset-y-0 right-0 hidden w-48 h-full text-blue lg:block transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <div className="relative px-4 pt-6 sm:px-6 lg:px-8"></div>

          <main className="px-4 mx-auto mt-10 max-w-7xl sm:mt-12 sm:px-8 lg:px-8 xl:mt-10">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl md:text-5xl">
                <span
                  className="block text-7xl sm:text-8xl xl:inline text-yellow"
                  style={{ textShadow: "5px 8px 5px #222" }}
                >
                  <em>Alpine</em>
                </span>
                <span className="block mt-2">Air Conditioning and Heating</span>
              </h1>
              <ul className="mt-12 font-bold text-white text-md">
                <li>
                  Office Hours Monday-Friday, 8:00am.-12:00pm. and 1:00pm.-4:00
                  pm.
                </li>
                <li>Flexible Scheduling Available</li>
                <li>(209) 295-6484</li>
                <li>Pioneer, CA License #714582</li>
              </ul>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <GatsbyImage
          className="object-cover w-full h-56 sm:h-72 md:h-64 lg:w-full lg:h-full"
          imgStyle={{ objectPosition: "bottom" }}
          image={getImage(props.image)}
          alt="header-image"
        />
      </div>
    </div>
  )
}

export default Header
