import React, { useState } from "react"
import { nanoid } from "nanoid"
import { Link } from "gatsby"
import { Transition } from "@headlessui/react"
import { MenuIcon } from "@heroicons/react/outline"
import logo from "../images/logo.png"
import Dropdown from "./UI/Dropdown"

function Navbar() {
  const [isOpen, setIsOpen] = useState(false)
  const navLinks = [
    {
      name: "Home",
      to: "/",
    },
    {
      name: "AC & Heating",
      dropdownItems: [
        {
          name: "Fujitsu",
          href: "/fujitsu",
        },
        {
          name: "Bryant",
          href: "/bryant ",
        },
        {
          name: "Ruud",
          href: "/ruud",
        },
      ],
    },
    {
      name: "Maintenance",
      to: "/maintenance",
    },
    {
      name: "Fabrication",
      to: "/fabrication",
    },
    {
      name: "Service Area",
      to: "/service-area",
    },
    {
      name: "About Us",
      to: "/about-us",
    },
  ]

  // use flatMap to merge the link.dropdownItems array into the mobileLinks array so it is one array
  const mobileLinks = navLinks.flatMap((link, i) =>
    link.dropdownItems ? (
      link.dropdownItems.map(item => (
        <Link
          to={item.href}
          key={nanoid()}
          className="block py-2 pr-4 mt-1 text-base font-bold link"
        >
          {item.name}
        </Link>
      ))
    ) : (
      <Link
        to={link.to}
        key={i}
        className="block py-2 pr-4 mt-1 text-base font-bold link"
      >
        {link.name}
      </Link>
    )
  )

  const links = navLinks.map((link, i) =>
    link.dropdownItems ? (
      <Dropdown key={nanoid()} header={link.name} items={link.dropdownItems} />
    ) : (
      <Link
        to={link.to}
        key={i}
        className="inline-flex items-center px-1 pt-1 ml-4 text-sm font-medium transition duration-150 ease-in-out border-b-2 border-transparent hover:border-dark text-blue hover:text-yellow"
      >
        {link.name}
      </Link>
    )
  )
  return (
    <nav className="w-full h-20 md:shadow bg-light">
      <div className="px-4 mx-auto sm:px-6 lg:px-8">
        <div className="flex justify-between h-20 max-w-6xl mx-auto">
          <div className="flex">
            <div className="flex items-center flex-shrink-0">
              <Link className="flex items-center space-x-1" to="/#top">
                <img src={logo} alt="" className="w-24" />
              </Link>
            </div>
          </div>
          <div className="hidden md:ml-6 md:flex md:items-center">
            <div className="relative ml-3">
              <div>
                <div className="hidden md:ml-6 md:flex">{links}</div>
              </div>
            </div>
          </div>
          <div className="flex items-center -mr-2 md:hidden">
            <button
              className={`inline-flex ${
                isOpen ? "bg-light text-black" : ""
              } items-center justify-center p-2 text-dark-white focus:outline-none rounded-md transition duration-150 ease-in-out`}
              aria-label="Main menu"
              aria-expanded="false"
              onClick={() => {
                setIsOpen(!isOpen)
              }}
            >
              <MenuIcon
                className={`${isOpen ? "text-black" : "text-blue"} w-6`}
              />
            </button>
          </div>
        </div>
      </div>

      <Transition
        show={isOpen}
        enter="transition ease-out relative z-20 duration-300 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-200 relative z-20 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="relative z-20 w-64 pt-2 pb-3 pl-4 mt-2 ml-auto mr-1 text-left rounded-md text-dark-white bg-light sm:w-1/3">
          {mobileLinks}
        </div>
      </Transition>
    </nav>
  )
}

export default Navbar
